import React from "react"
import Layout from "../../components/Layout"
import SearchBar from "../../components/SearchBar"

const SearchPage = () => (
  <Layout>
    <SearchBar />
  </Layout>
)

export default SearchPage
